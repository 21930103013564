import React, { useCallback, useEffect, useState } from "react";
import digitalImg from "../../../../assets/img/digitalSkill.svg";
import functionalImg from "../../../../assets/img/functionalSkill.svg";
import leaderImg from "../../../../assets/img/leaderSkill.svg";
import "./CriticalSkill.css";

import ReactLoading from "react-loading";
import ReactTooltip from "react-tooltip";
import tooltipIcon from "../../../../assets/img/tooltip.svg";
import BtnVideo from "../../../../component/btnVideo/BtnVideo";
import { BASE_URL } from "../../../../constant/apiUrl";
import APIUtils from "../../../../utils/apiUtils";
import "./CriticalSkill.css";
import { Modal } from "react-bootstrap";
import ReactPlayer from "react-player";

const tip1 = "Percentage of total open jobs that required this skill last year";
const tip2 =
  "Critical skill for the job family, that is not mentioned in most job postings";

const dataSample = [
  {
    category: "Functional",
    data: [],
  },
  {
    category: "Digital",
    data: [],
  },
  {
    category: "Leadership",
    data: [],
  },
];

const CriticalSkill = React.forwardRef((props, ref) => {
  const [data, setData] = useState(dataSample);
  const [showVideo, setShowVideo] = useState(false);

  useEffect(() => {
    setData(dataSample);
    if (props.data) {
      const url =
        BASE_URL +
        `/jobintelligence/v1/Sectors/${3}/Occupations/${
          props.data?.id
        }/getCriticalSkills`;

      const promiseCritical = new Promise((resolve, reject) => {
        const data = APIUtils.post(url);
        resolve(data);
      });

      promiseCritical.then((dataAPI) => {
        const obj = JSON.parse(dataAPI?.data?.body);

        const dataCritical = JSON.parse(JSON.stringify(dataSample));

        for (let j in dataCritical) {
          for (let i = 0; i < Object.keys(obj.Category).length; i++) {
            if (obj.Category[i] === dataCritical[j].category) {
              let tmp = {};
              tmp.Family = obj.Family[i];
              tmp.Family_Id = obj.Family_Id[i];
              tmp.Id = obj.Id[i];
              tmp.Order = obj.Order[i];
              tmp.Skill = obj.Skill[i];
              tmp.Skill_Id = obj.Skill_Id[i];
              tmp.Skill_Percentage = obj.Skill_Percentage[i];
              dataCritical[j].data.push(tmp);
            }
          }
        }
        setData(dataCritical);
      });
    }
  }, [props.data]);

  const handleCloseModal = useCallback(() => {
    setShowVideo(false);
  }, []);

  const onBtnClicked = useCallback(() => {
    setShowVideo(true);
  }, []);

  return (
    props.data && (
      <div>
        <>
          <div
            style={{
              backgroundColor: "transparent",
            }}
            className={"gap-component"}
          >
            <h2 className="txt-sub-title">top skills by category</h2>
            Critical skills are the ones that are most in demand for the
            selected job family. The number denotes the share of total job
            opportunities that require the skill.
            <div className="row" style={{ marginTop: 20 }}>
              <div className="col-lg-4 col-sm-6 d-flex justify-content-center">
                <div>
                  <div style={{ display: "flex", justifyContent: "center" }}>
                    <img
                      src={functionalImg}
                      alt="Thumb"
                      className="critical-img"
                    />
                  </div>
                  <div className="critical-title">
                    {data[0]?.category} Skills
                  </div>
                  {data[0]?.data.length !== 0 ? (
                    data[0]?.data.map((item, key) => {
                      return (
                        <div key={key} className="critical-card">
                          <div className="critical-txt title">
                            {item?.Skill}
                          </div>
                          <a
                            data-for="main1"
                            data-tip={item?.Skill_Percentage ? tip1 : tip2}
                            data-iscapture="true"
                          >
                            <div className="critical-txt percent">
                              {item?.Skill_Percentage
                                ? Math.round(item?.Skill_Percentage) + "%"
                                : "\xa0\xa0\xa0\xa0-"}

                              <img
                                src={tooltipIcon}
                                alt="Thumb"
                                style={{ width: 13, height: 13, marginLeft: 5 }}
                              />
                              <ReactTooltip
                                id="main1"
                                className="tooltip-right"
                                place={"right"}
                                // type={type}
                                // effect={effect}
                                multiline={true}
                                overridePosition={(
                                  { top, left },
                                  currentEvent,
                                  currentTarget,
                                  node
                                ) => {
                                  node.style.width = "250px"; // Your custom width
                                  return {
                                    top,
                                    left,
                                  };
                                }}
                              />
                            </div>
                          </a>
                        </div>
                      );
                    })
                  ) : (
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                      }}
                    >
                      <ReactLoading
                        type={"bubbles"}
                        color={"rgba(0, 0, 0, 0.12)"}
                        height={"100%"}
                        width={"30%"}
                      />
                    </div>
                  )}
                </div>
              </div>
              <div className="col-lg-4 col-sm-6 d-flex justify-content-center">
                <div>
                  <div style={{ display: "flex", justifyContent: "center" }}>
                    <img
                      src={digitalImg}
                      alt="Thumb"
                      className="critical-img"
                    />
                  </div>
                  <div className="critical-title">
                    {data[1]?.category} Skills
                  </div>
                  {data[1]?.data.length !== 0 ? (
                    data[1]?.data.map((item, key) => {
                      return (
                        <div key={key} className="critical-card">
                          <div className="critical-txt title">
                            {item?.Skill}
                          </div>
                          <a
                            data-for="main2"
                            data-tip={item?.Skill_Percentage ? tip1 : tip2}
                            data-iscapture="true"
                          >
                            <div className="critical-txt percent">
                              {item?.Skill_Percentage
                                ? Math.round(item?.Skill_Percentage) + "%"
                                : "\xa0\xa0\xa0\xa0-"}

                              <img
                                src={tooltipIcon}
                                alt="Thumb"
                                style={{ width: 13, height: 13, marginLeft: 5 }}
                              />
                              <ReactTooltip
                                id="main2"
                                className="tooltip-right"
                                place={"right"}
                                // type={type}
                                // effect={effect}
                                multiline={true}
                                overridePosition={(
                                  { top, left },
                                  currentEvent,
                                  currentTarget,
                                  node
                                ) => {
                                  node.style.width = "250px"; // Your custom width
                                  return {
                                    top,
                                    left,
                                  };
                                }}
                              />
                            </div>
                          </a>
                        </div>
                      );
                    })
                  ) : (
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                      }}
                    >
                      <ReactLoading
                        type={"bubbles"}
                        color={"rgba(0, 0, 0, 0.12)"}
                        height={"100%"}
                        width={"30%"}
                      />
                    </div>
                  )}
                </div>
              </div>
              <div className="col-lg-4 col-sm-12 d-flex justify-content-center">
                <div>
                  <div style={{ display: "flex", justifyContent: "center" }}>
                    <img src={leaderImg} alt="Thumb" className="critical-img" />
                  </div>
                  <div className="critical-title">
                    {data[2]?.category} Skills
                  </div>
                  {data[2]?.data.length !== 0 ? (
                    data[2]?.data.map((item, key) => {
                      return (
                        <div key={key} className="critical-card">
                          <div className="critical-txt title">
                            {item?.Skill}
                          </div>
                          <a
                            data-for="main3"
                            data-tip={item?.Skill_Percentage ? tip1 : tip2}
                            data-iscapture="true"
                          >
                            <div className="critical-txt percent">
                              {item?.Skill_Percentage
                                ? Math.round(item?.Skill_Percentage) + "%"
                                : "\xa0\xa0\xa0\xa0-"}

                              <img
                                src={tooltipIcon}
                                alt="Thumb"
                                style={{ width: 13, height: 13, marginLeft: 5 }}
                              />
                              <ReactTooltip
                                id="main3"
                                place={"right"}
                                className="tooltip-right"
                                multiline={true}
                                overridePosition={(
                                  { top, left },
                                  currentEvent,
                                  currentTarget,
                                  node
                                ) => {
                                  node.style.width = "250px"; // Your custom width
                                  return {
                                    top,
                                    left,
                                  };
                                }}
                              />
                            </div>
                          </a>
                        </div>
                      );
                    })
                  ) : (
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                      }}
                    >
                      <ReactLoading
                        type={"bubbles"}
                        color={"rgba(0, 0, 0, 0.12)"}
                        height={"100%"}
                        width={"30%"}
                      />
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </>
        {/* <a
          data-for="main"
          data-tip="Hello multiline tooltip Hello multiline tooltip Hello multiline tooltip Hello multiline tooltip"
          data-iscapture="true"
        >
          ◕‿‿◕
        </a> */}
        {props?.video?.["1"] && (
          <BtnVideo
            title={"see how you can build these skills in your workforce"}
            onBtnClicked={onBtnClicked}
          />
        )}
        <Modal show={showVideo} centered onHide={handleCloseModal}>
          <ReactPlayer url={props?.video?.["1"]} controls={true} />
        </Modal>
      </div>
    )
  );
});

export default React.memo(CriticalSkill);
