import React, { useCallback, useEffect, useRef, useState } from "react";

import { Modal } from "react-bootstrap";
import close from "../../../assets/img/closeBtn.svg";

import content1 from "../../../assets/img/content1.svg";
import content2 from "../../../assets/img/content2.svg";
import content3 from "../../../assets/img/content3.svg";
import content4 from "../../../assets/img/content4.svg";
import content5 from "../../../assets/img/content5.svg";

import arrowRight from "../../../assets/img/arrowRight.svg";

// Import Swiper styles
import "swiper/css";
import "swiper/css/navigation";

// import required modules

import { strim } from "../../../helper/stringValidate";
import "./DisprzContent.css";

const arrImg = [content1, content2, content3, content4, content5];

const ModalContent = (props) => {
  const [imgArr, setImgArr] = useState(arrImg);
  const [showMore, setShowMore] = useState(true);

  const listInnerRef = useRef();

  useEffect(() => {
    let tmp = [...arrImg];
    for (let i = 0; i < Math.ceil(props.dataModal?.length / 5); i++) {
      tmp = [...arrImg, ...arrImg];
    }
    setImgArr(tmp);
  }, [props.dataModal]);

  const onHide = useCallback(() => {
    props.onHide();
  }, []);

  const onScroll = () => {
    if (listInnerRef.current) {
      setShowMore(false);
    }
  };

  return (
    <Modal
      contentClassName={"modal-content-container"}
      show={props.show}
      centered
      size={"xl"}
      onHide={onHide}
    >
      <h2 className="txt-sub-title-modal">{props.title}</h2>
      <img
        className="btn-close-modal"
        onClick={props.onHide}
        src={close}
        alt="Thumb"
      />

      {props.lengthList > 4 && showMore ? (
        <div
          // onClick={onClickNext}
          className="btn-navigation"
        >
          <img src={arrowRight} alt="Thumb" />
          <div className={"more-note"}>+{props.lengthList - 4}</div>
        </div>
      ) : null}

      <div
        onScroll={() => onScroll()}
        ref={listInnerRef}
        className="modal-list-container"
      >
        {props?.dataModal?.map((i, key) => {
          return (
            <div key={key} className="modal-item">
              <div className="img-content-view">
                <img src={imgArr[key]} alt="Thumb" className="img-content" />
              </div>
              <div className="modal-item-des">
                <div className="modal-item-title">{i?.course_title}</div>
                <div className="modal-item-type">{i?.content_type}</div>
                <div
                  className="modal-item-sub"
                  dangerouslySetInnerHTML={
                    i?.course_description?.length > 250
                      ? {
                          __html: `${strim(i?.course_description, 130)}...`,
                        }
                      : {
                          __html: i?.course_description,
                        }
                  }
                />
              </div>
            </div>
          );
        })}
      </div>
      <div className="modal-item-sub title">
        Get access to the above content and many more with the help of disprz.
      </div>
    </Modal>
  );
};

export default React.memo(ModalContent);
