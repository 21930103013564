import React from "react";
import posterGetPhone from "../../assets/img/posterGetPhone.webp";
import checkIcon from "../../assets/img/check.svg";

import "./ModalGetPhone.css";

const ModalGetPhone = (props) => {
  // const [phone, setPhone] = useState("");

  // const onChangePhone = useCallback((event) => {
  //   setPhone(event.target.value);
  //   props.onChangePhone();
  // }, []);

  return (
    <>
      <div className="header-container">
        <div style={{ display: "flex" }}>
          <img src={checkIcon} alt="img" className="check-icon" />
          <p className="modal-title">
            Thank you for your interest in disprz. <br />
            We will soon be in touch with you
          </p>
        </div>
        <p className="modal-sub-title">We’ll show you how to:</p>
        <p className="modal-sub-title des">
          1. Benchmark your workforce’s skills <br />
          2. Develop personalised learning paths <br />
          3. Assess your team’s progress
        </p>

        <img src={posterGetPhone} alt="img" className="poster-getphone" />
      </div>
      {/* <p className="modal-sub-txt">
        Yes, I would like to schedule a free consultation.
      </p>
      <input
        className="popup-input-phone"
        id="popup-input-phone"
        type="tel"
        placeholder="Enter Phone Number"
        value={phone}
        onChange={onChangePhone}
      />
      <div className="modal-txt-policy">
        <div style={{ display: "flex" }}>
          <Form.Check
            type={"checkbox"}
            id={`checkbox-policy`}
            // label={`default ${type}`}
            style={{ marginRight: 10 }}
          />
          I agree to receiving scheduling messages over WhatsApp
        </div>
        <p>Don’t worry, we won’t spam you.</p>
      </div>

      <div className="modal-button-container">
        <div
          //  onClick={onClickNext}
          className="modal-btn-view"
          style={{ marginBottom: 20 }}
        >
          Book my call
        </div>
      </div> */}
    </>
  );
};

export default React.memo(ModalGetPhone);
