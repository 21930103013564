import React, { useCallback, useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import ReactPlayer from "react-player";
import { Tab, TabList, TabPanel, Tabs } from "react-tabs";
import BtnVideo from "../../../component/btnVideo/BtnVideo";
import { dataSample } from "../../../utils/Constant";
import ContentItem from "./ContentItem";

import "./DisprzContent.css";
import OpenContentItem from "./OpenContentItem";

function DisprzContent(props) {
  const [tabIndex, setTabIndex] = useState(0);
  const [data, setData] = useState([]);
  const [showVideo, setShowVideo] = useState(false);

  useEffect(() => {
    if (props.menuJobSelectedMain && props.dataContent) {
      setData([]);

      const obj = JSON.parse(props.dataContent?.data?.body);
      const dataContent = JSON.parse(JSON.stringify(dataSample));

      for (let j in dataContent) {
        for (let i = 0; i < Object.keys(obj.category).length; i++) {
          if (obj.category[i] === dataContent[j].category) {
            let tmp = {};
            const tmpArr = [];
            const tmpArr2 = [];
            for (const [key] of Object.entries(obj)) {
              tmp[key] = obj[key][i];
              if (key.includes("pro")) {
                if (
                  key.includes("youtube") ||
                  key.includes("articles") ||
                  key.includes("podcasts")
                ) {
                  let tmpObj = {};
                  tmpObj.name = key;
                  tmpObj.value = tmp[key];
                  tmpArr2.push(tmpObj);
                } else {
                  let tmpObj = {};
                  tmpObj.name = key;
                  tmpObj.value = tmp[key];
                  tmpArr.push(tmpObj);
                }
              }
            }
            tmp.dataPro = tmpArr;
            tmp.dataOpenContent = tmpArr2;
            tmp.dataContent = [];
            dataContent[j].data.push(tmp);
          }
        }
      }
      setData(dataContent);
    }
  }, [props.menuJobSelectedMain, props.dataContent, props.dataContentSkill]);

  const handleCloseModal = useCallback(() => {
    setShowVideo(false);
  }, []);

  const onBtnClicked = useCallback(() => {
    setShowVideo(true);
  }, []);

  const onSelectPage = useCallback((index) => {
    setTabIndex(index);
  }, []);

  return (
    props.menuJobSelectedMain &&
    props.dataContent && (
      <div>
        {!data.length === 0 ? null : (
          <>
            <div
              style={{
                backgroundColor: "transparent",
              }}
              className={"gap-component"}
            >
              <h2 className="txt-sub-title sub">{props.title}</h2>
              <Tabs
                selectedIndex={tabIndex}
                onSelect={onSelectPage}
                selectedTabClassName="tab-button"
                style={{ border: 0, marginTop: 30 }}
              >
                <TabList className={"header-tab"}>
                  <Tab
                    className={
                      tabIndex !== 0 ? "tab-button inactive" : "tab-button"
                    }
                    style={{ width: "33.3%" }}
                  >
                    functional skills
                  </Tab>
                  <Tab
                    className={
                      tabIndex !== 1 ? "tab-button inactive" : "tab-button"
                    }
                    style={{ width: "33.3%" }}
                  >
                    digital skills
                  </Tab>
                  <Tab
                    className={
                      tabIndex !== 2 ? "tab-button inactive" : "tab-button"
                    }
                    style={{ width: "33.3%" }}
                  >
                    leadership skills
                  </Tab>
                </TabList>
                <TabPanel style={{ background: "white" }}>
                  {props.isFree ? (
                    <OpenContentItem
                      data={data?.[0]}
                      title={props.title}
                      dataContent={JSON?.parse(
                        props.dataContentSkill?.data?.body || "{}"
                      )}
                    />
                  ) : (
                    <ContentItem
                      data={data?.[0]}
                      title={props.title}
                      dataContent={JSON?.parse(
                        props.dataContentSkill?.data?.body || "{}"
                      )}
                    />
                  )}
                </TabPanel>
                <TabPanel style={{ background: "white" }}>
                  {props.isFree ? (
                    <OpenContentItem
                      data={data?.[1]}
                      title={props.title}
                      dataContent={JSON?.parse(
                        props.dataContentSkill?.data?.body || "{}"
                      )}
                    />
                  ) : (
                    <ContentItem
                      data={data?.[1]}
                      title={props.title}
                      dataContent={JSON?.parse(
                        props.dataContentSkill?.data?.body || "{}"
                      )}
                    />
                  )}
                </TabPanel>
                <TabPanel style={{ background: "white" }}>
                  {props.isFree ? (
                    <OpenContentItem
                      data={data?.[2]}
                      title={props.title}
                      dataContent={JSON?.parse(
                        props.dataContentSkill?.data?.body || "{}"
                      )}
                    />
                  ) : (
                    <ContentItem
                      data={data?.[2]}
                      title={props.title}
                      dataContent={JSON?.parse(
                        props.dataContentSkill?.data?.body || "{}"
                      )}
                    />
                  )}
                </TabPanel>
              </Tabs>
              {props.isFree && props?.video?.["2"] && (
                <BtnVideo
                  title={"see how disprz helps you curate content"}
                  onBtnClicked={onBtnClicked}
                />
              )}
            </div>
          </>
        )}
        <Modal show={showVideo} centered onHide={handleCloseModal}>
          <ReactPlayer url={props?.video?.["2"]} controls={true} />
        </Modal>
      </div>
    )
  );
}

export default React.memo(DisprzContent);
