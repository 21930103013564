import React, {useEffect} from "react";

const HubspotContactForm = (props) => {

    useEffect(() => {
        const script = document.createElement('script');
        script.src='https://js.hsforms.net/forms/embed/v2.js';
        document.body.appendChild(script);
        script.addEventListener('load', () => {
            if (window.hbspt) {
                window.hbspt.forms.create({
                    region: props.region,
                    portalId: props.portalId,
                    formId: props.formId,
                    target: '#hubspotForm',
                    onFormReady: ($form) => {
                        clearForm($form);
                    },
                    onFormSubmitted: ($form) => {
                        props.onContactFormSubmit();
                    }
                })
            }
        });
    }, []);

    const clearForm = ($form) => {
        $form[1].value = '';
        $form[2].value = '';
        $form[3].value = '';
        $form[4].value = '';
        $form[5].selectedIndex = 0;
        $form[6].selectedIndex = 0;
        $form[7].selectedIndex = 0;
        $form[8].selectedIndex = 0;
    }

    return (
        <div>
            <div id="hubspotForm" />
        </div>
    );

}

export default HubspotContactForm;