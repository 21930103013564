import React, { useCallback, useEffect, useRef, useState } from "react";
import ReactLoading from "react-loading";
import checkSkillImg from "../../../assets/img/checkSkill.svg";
import arrow1 from "../../../assets/img/arrow1.svg";
import arrow2 from "../../../assets/img/arrow2.svg";
import arrow3 from "../../../assets/img/arrow3.svg";
import arrow4 from "../../../assets/img/arrow4.svg";
import arrow5 from "../../../assets/img/arrow5.svg";
import tooltipIcon from "../../../assets/img/tooltip.svg";

import "./CheckSkill.css";
import CheckSkillCard from "./CheckSkillCard";
import APIUtils from "../../../utils/apiUtils";
import { endPoint } from "../../../constant/apiUrl";

function CheckSkillGraph(props) {
  const [data, setData] = useState([]);
  const [occupationSelected, setOccupationSelected] = useState();
  const refBtn = useRef();

  const onOccupationSelect = useCallback(
    (item, trackKey, itemKey) => () => {
      refBtn.current.scrollIntoView();
      const tmp = [...data];
      for (let j in data) {
        for (let i = 0; i < data[j].data.length; i++) {
          tmp[j].data[i].selected = false;
        }
      }
      tmp[trackKey].data[itemKey].selected = true;
      tmp[trackKey].data[itemKey].trackData = data[trackKey].track_name;
      setData(tmp);
      setOccupationSelected(tmp[trackKey].data[itemKey]);
    },
    [data]
  );

  useEffect(() => {
    const fetchData = async () => {
      const dataJobApi = await APIUtils.get(endPoint.getJobFamily);
      const dataJob = JSON.parse(dataJobApi?.data?.body);
      const dataTrackApi = await APIUtils.get(endPoint.getTracks);
      const dataTrack = dataTrackApi?.data?.body;
      let dataJobMapping = [...dataTrack];
      for (let j in dataJobMapping) {
        dataJobMapping[j].data = [];
        for (let i = 0; i < Object.keys(dataJob.Occupation).length; i++) {
          if (dataJob.track_id[i] === dataTrack[j].id) {
            let tmp = {};
            tmp.occupation = dataJob.Occupation[i];
            tmp.id = dataJob.id[i];
            tmp.isPopular = dataJob.is_popular[i];
            tmp.sectorId = dataJob.sector_id[i];
            tmp.trackId = dataJob.track_id[i];
            dataJobMapping[j].data.push(tmp);
          }
        }
      }
      setData(dataJobMapping);
    };

    fetchData().catch(console.error);
  }, []);

  return (
    <div>
      {data.length === 0 ? (
        <div className="gap-component" style={{ marginLeft: "45%" }}>
          <ReactLoading
            type={"bubbles"}
            color={"rgba(0, 0, 0, 0.12)"}
            height={"10%"}
            width={"10%"}
          />
        </div>
      ) : (
        <>
          <div className="graph-container">
            <div className="graph">
              <img
                src={checkSkillImg}
                alt="Thumb"
                // style={{ position: "absolute", left: 0 }}
                className={"img-checkSkill"}
              />
              <img src={arrow1} alt="Thumb" className={"arrow arrow1"} />
              <img src={arrow2} alt="Thumb" className={"arrow arrow2"} />
              <img src={arrow3} alt="Thumb" className={"arrow arrow3"} />
              <img src={arrow4} alt="Thumb" className={"arrow arrow4"} />
              <img src={arrow5} alt="Thumb" className={"arrow arrow5"} />
              <div className="card-check-skill-container">
                <div className="card-item-container item1">
                  <CheckSkillCard
                    data={data[1]}
                    trackKey={1}
                    onOccupationSelect={onOccupationSelect}
                  />
                </div>
                <div className="card-item-container item4">
                  <CheckSkillCard
                    data={data[4]}
                    trackKey={4}
                    onOccupationSelect={onOccupationSelect}
                  />
                </div>
                <div className="card-item-container item2">
                  <CheckSkillCard
                    data={data[0]}
                    trackKey={0}
                    onOccupationSelect={onOccupationSelect}
                  />
                </div>
                <div className="card-item-container item3">
                  <CheckSkillCard
                    data={data[2]}
                    trackKey={2}
                    onOccupationSelect={onOccupationSelect}
                  />
                </div>

                <div className="card-item-container item5">
                  <CheckSkillCard
                    data={data[3]}
                    trackKey={3}
                    onOccupationSelect={onOccupationSelect}
                  />
                </div>
              </div>
            </div>
          </div>
          <div>
            <div
              ref={refBtn}
              style={{ display: "flex", justifyContent: "center" }}
            >
              <button
                disabled={occupationSelected ? false : true}
                className={`check-skill-graph-button ${
                  occupationSelected ? "" : "inactive"
                }`}
                onClick={props.onCheckSkillClicked(occupationSelected)}
              >
                check skills intelligence
              </button>
            </div>
            {!occupationSelected ? (
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
                className={"footer-note"}
              >
                <img
                  src={tooltipIcon}
                  alt="Thumb"
                  style={{ marginRight: 10 }}
                />
                Please select one job family to continue
              </div>
            ) : (
              <div style={{ height: 30 }}></div>
            )}
          </div>
        </>
      )}
    </div>
  );
}

export default React.memo(CheckSkillGraph);
