const validateEmail = (email) => {
  //   const expression = /^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+\.[a-zA-Z0-9-.]+$/;
  var emailRegex = /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/;
  var freeRegex =
    "[a-zA-Z0-9_.+-]+@((hotmail)|(yahoo)|(gmail)|(rediffmail)|(outlook)|(edcast)|(degreed)|(csod)|(360learning)|(skillsoft))\\.[a-zA-Z0-9-.]{2,4}";

  if (!email.match(emailRegex)) {
    return false;
  }
  if (email.match(freeRegex)) {
    return false;
  }
  return true;
};

const validateName = (name) => {
  var nameRegex = /^[A-z ]+$/;

  if (!name.match(nameRegex)) {
    return false;
  }

  return true;
};

const strim = (txt, length) => {
  var yourString = txt; //replace with your string.
  var maxLength = length; // maximum number of characters to extract

  //trim the string to the maximum length
  var trimmedString = yourString.substr(0, maxLength);
  trimmedString = trimmedString.substr(
    0,
    Math.min(trimmedString.length, trimmedString.lastIndexOf(" "))
  );
  return trimmedString;
};

export { validateEmail, validateName, strim };
