const REQUEST_TIMEOUT = 300000;
const ACCESS_TOKEN_STORAGE =
  "eyJhbGciOiJIUzI1NiIsImlhsfsdfsdzNCwiZXhwIjoxNTMwNzksdfsdsdRF.eyJpZCI6MX0.YhZvjKiafmv-qrvAxVo7UKQuohS2vkF-9scpuerhtychsht";

const buildURLWithParams = (url, params = {}) => {
  let requestedURL = url;
  if (params) {
    const keys = Object.keys(params);

    if (Array.isArray(keys) && keys.length > 0) {
      requestedURL += "?";
      for (var property of keys) {
        const index = keys.indexOf(property);
        if (index > 0 && index < keys.length) {
          requestedURL += "&";
        }
        requestedURL += `${property}=${params[property]}`;
      }
    }
  }
  return requestedURL;
};

const headersDefault = {
  "Content-Type": "application/json",
  Accept: "application/json; charset=utf-8",
  "x-api-token": ACCESS_TOKEN_STORAGE,
};

export default class APIUtils {
  constructor() {
    this.accessToken = "";
  }

  static async get(url, config = { headers: {}, params: {} }) {
    return new Promise((resolve, reject) => {
      const { headers, params, ...restConfig } = config;
      const requestedURL = buildURLWithParams(url, params);

      // eslint-disable-next-line no-undef
      const controller = new AbortController();
      const signal = controller.signal;

      const fetchConfig = {
        headers: {
          ...headersDefault,
          ...config.headers,
        },
        ...restConfig,
        method: "GET",
        signal,
      };

      setTimeout(() => {
        controller.abort();
      }, REQUEST_TIMEOUT);

      fetch(requestedURL, fetchConfig)
        .then(async (response) => {
          let responseJson = {};
          try {
            responseJson = await response.json();
          } catch (err) {}

          if (response.status >= 400 && response.status <= 499) {
            throw { data: responseJson, status: response.status };
          }
          if (response.status >= 200 && response.status <= 299) {
            return { data: responseJson, status: response.status };
          }
          throw { data: responseJson, status: response.status };
        })
        .then((res) => {
          resolve(res);
        })
        .catch((error) => {
          console.log(">>>>>error>>>>>", { url: requestedURL, error });
          reject(error);
        });
    });
  }

  static async post(url, config = { headers: {} }) {
    return new Promise((resolve, reject) => {
      const { headers, body, params, ...restConfig } = config;

      // eslint-disable-next-line no-undef
      const controller = new AbortController();
      const signal = controller.signal;

      let tmpBody = config.body ? JSON.stringify(config.body) : {};

      if (config?.headers?.["Content-Type"] === "multipart/form-data") {
        tmpBody = config.body;
      }

      const fetchConfig = {
        headers: {
          ...headersDefault,
          ...config.headers,
        },
        ...restConfig,
        signal,
        method: "POST",
        body: tmpBody,
      };
      setTimeout(() => {
        controller.abort();
      }, REQUEST_TIMEOUT);


      fetch(url, fetchConfig)
        .then(async (response) => {
          let responseJson = {};
          try {
            responseJson = await response.json();
          } catch (err) {}

          if (response.status >= 400 && response.status <= 499) {
            throw { data: responseJson, status: response.status };
          }
          if (response.status >= 200 && response.status <= 299) {
            return { data: responseJson, status: response.status };
          }
          throw { data: responseJson, status: response.status };
        })
        .then((res) => {
          resolve(res);
        })
        .catch((error) => {
          console.log(">>>>>error>>>>>", error);
          reject(error);
        });
    });
  }

  static delete(url, config = { headers: {} }) {
    return new Promise((resolve, reject) => {
      const { headers, body, params, ...restConfig } = config;

      // eslint-disable-next-line no-undef
      const controller = new AbortController();
      const signal = controller.signal;

      const fetchConfig = {
        headers: {
          ...headersDefault,
          ...config.headers,
        },
        ...restConfig,
        signal,
        method: "DELETE",
        body: JSON.stringify(config.body),
      };
      setTimeout(() => {
        controller.abort();
      }, REQUEST_TIMEOUT);

      fetch(url, fetchConfig)
        .then(async (response) => {
          let responseJson = {};
          try {
            responseJson = await response.json();
          } catch (err) {}

          if (response.status >= 400 && response.status <= 499) {
            throw { data: responseJson, status: response.status };
          }
          if (response.status >= 200 && response.status <= 299) {
            return { data: responseJson, status: response.status };
          }
          throw { data: responseJson, status: response.status };
        })
        .then((res) => {
          resolve(res);
        })
        .catch((error) => {
          console.log(">>>>>error>>>>>", error);
          reject(error);
        });
    });
  }

  static async put(url, config = { headers: {} }) {
    return new Promise((resolve, reject) => {
      const { headers, body, params, ...restConfig } = config;

      // eslint-disable-next-line no-undef
      const controller = new AbortController();
      const signal = controller.signal;

      const fetchConfig = {
        headers: {
          ...headersDefault,
          ...config.headers,
        },
        ...restConfig,
        signal,
        method: "PUT",
        body: JSON.stringify(config.body),
      };
      setTimeout(() => {
        controller.abort();
      }, REQUEST_TIMEOUT);
      console.log(">>>>>request>>>>>", {
        url,
        config: fetchConfig,
      });
      fetch(url, fetchConfig)
        .then(async (response) => {
          let responseJson = {};
          try {
            responseJson = await response.json();
          } catch (err) {}

          if (response.status >= 400 && response.status <= 499) {
            throw { data: responseJson, status: response.status };
          }
          if (response.status >= 200 && response.status <= 299) {
            return { data: responseJson, status: response.status };
          }
          throw { data: responseJson, status: response.status };
        })
        .then((res) => {
          resolve(res);
        })
        .catch((error) => {
          console.log(">>>>>error>>>>>", error);
          reject(error);
        });
    });
  }

  static async postForm(url, config = { headers: {} }) {
    return new Promise((resolve, reject) => {
      const { headers, body, params, ...restConfig } = config;

      // eslint-disable-next-line no-undef
      const controller = new AbortController();
      const signal = controller.signal;

      let details = config.body;

      var formBody = [];
      for (var property in details) {
        var encodedKey = encodeURIComponent(property);
        var encodedValue = encodeURIComponent(details[property]);
        formBody.push(encodedKey + "=" + encodedValue);
      }
      formBody = formBody.join("&");


      const fetchConfig = {
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
        },
        method: "POST",
        body: formBody,
      };
      setTimeout(() => {
        controller.abort();
      }, REQUEST_TIMEOUT);

      fetch(url, fetchConfig)
        .then(async (response) => {
          let responseJson = {};
          try {
            responseJson = await response.json();
          } catch (err) {}

          if (response.status >= 400 && response.status <= 499) {
            throw { data: responseJson, status: response.status };
          }
          if (response.status >= 200 && response.status <= 299) {
            return { data: responseJson, status: response.status };
          }
          throw { data: responseJson, status: response.status };
        })
        .then((res) => {
          resolve(res);
        })
        .catch((error) => {
          console.log(">>>>>error>>>>>", error);
          reject(error);
        });
    });
  }
}
