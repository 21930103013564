import React, {
  useCallback,
  useEffect,
  useImperativeHandle,
  useState,
} from "react";
import pyramidImg from "../../../assets/img/pyramid.svg";
import pyramidImg4 from "../../../assets/img/pyramid4.svg";
import pyramidImg5 from "../../../assets/img/pyramid5.svg";
import pyramidImg6 from "../../../assets/img/pyramid6.svg";

import { endPoint } from "../../../constant/apiUrl";
import APIUtils from "../../../utils/apiUtils";
import ReactLoading from "react-loading";
import wave from "../../../assets/img/wave.svg";
import wave2 from "../../../assets/img/wave2.svg";

import "./Pyramid.css";

const pyramidAsset = [
  "",
  "",
  "",
  pyramidImg,
  pyramidImg4,
  pyramidImg5,
  pyramidImg6,
];

const Pyramid = React.forwardRef((props, ref) => {
  const [data, setData] = useState([]);
  const [dataApi, setDataApi] = useState();

  useImperativeHandle(ref, () => ({
    clearData() {
      setData([]);
    },
  }));

  useEffect(() => {
    const fetchData = async () => {
      const data = await APIUtils.post(endPoint.getPyramidGraph);
      const obj = JSON.parse(data.data.body);
      setDataApi(obj);
    };
    if (!dataApi) {
      fetchData().catch(console.error);
    }
  }, [dataApi, props.data]);

  useEffect(() => {
    if (dataApi) {
      if (props.data) {
        let tmpArr = [];

        const tmp = dataApi?.[`${props?.data?.trackData}`];
        if (tmp) {
          let key = Object?.keys(tmp);
          let value = Object?.values(tmp);

          for (let i = 0; i < Object.keys(tmp).length; i++) {
            let tmpObj = {};
            tmpObj.name = key[i];
            tmpObj.value = value[i];
            tmpArr.push(tmpObj);
          }
          tmpArr.sort((a, b) => parseFloat(a.value) - parseFloat(b.value));
          setData(tmpArr);
        }
      }
    }
  }, [props.data, dataApi]);

  const renderPyramid = useCallback(
    (count) => {
      return (
        <div className="pyramid-container">
          <div className="pyramid-content">
            <img
              src={pyramidAsset[count]}
              alt="Thumb"
              className="pyramid-img"
            />
            <div className={`pyramid-line-container pyramid${count}`}>
              {data.map((i, key) => {
                return (
                  <div
                    key={key}
                    className={`pyramid-line ${
                      key + 1 === 1 ? "" : `line${key + 1}`
                    } pyramid${count}`}
                    style={{
                      position: "absolute",
                    }}
                  >
                    <div
                      className={`pyramid-txt percent pyramid${count}`}
                      // style={{ backgroundColor: "green" }}
                    >
                      {i?.value}%
                    </div>
                    <div
                      className={`pyramid-txt title pyramid${count}`}
                      // style={{ backgroundColor: "red" }}
                    >
                      {i?.name}
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
        </div>
      );
    },
    [data]
  );

  return (
    <div>
      {data.length === 0 ? null : (
        // <div style={{ marginLeft: "45%", marginTop: 100 }}>
        //   <ReactLoading
        //     type={"bubbles"}
        //     color={"rgba(0, 0, 0, 0.12)"}
        //     height={"10%"}
        //     width={"10%"}
        //   />
        // </div>
        <>
          <div style={{ position: "absolute", zIndex: -9999 }}>
            <img src={wave2} alt="Thumb" style={{ width: "100%" }} />
            <img src={wave} alt="Thumb" style={{ width: "100%" }} />
          </div>
          <div
            style={{
              backgroundColor: "transparent",
            }}
            className={"gap-component"}
          >
            <h2 className="txt-sub-title">demand pyramid</h2>
            The demand pyramid provides an overview of your selected track and
            the ratio of jobs for each job family in the track.
            {renderPyramid(data.length)}
          </div>
        </>
      )}
    </div>
  );
});

export default React.memo(Pyramid);
