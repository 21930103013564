import React, { useCallback, useState } from "react";

import "./BtnVideo.css";
import playIcon from "../../assets/img/playIcon.svg";

const BtnVideo = (props) => {
  return (
    <div className="btn-video-container">
      <div className="btn-video" onClick={props.onBtnClicked}>
        <img src={playIcon} alt="" />
        <div className="btn-title">{props.title}</div>
      </div>
    </div>
  );
};

export default React.memo(BtnVideo);
