import React, { useCallback, useEffect, useRef, useState } from "react";

import { Modal } from "react-bootstrap";
import closeIcon from "../../assets/img/close.svg";
import posterImg from "../../assets/img/posterImg.gif";
import snowflake from "../../assets/img/snowflake.svg";
import star from "../../assets/img/star.svg";
import wave from "../../assets/img/wave.svg";
import wave2 from "../../assets/img/wave2.svg";

import moment from "moment";
import ReactLoading from "react-loading";
import JobTitle from "../../component/jobTitle/JobTitle";
import { BASE_URL } from "../../constant/apiUrl";
import { validateEmail, validateName } from "../../helper/stringValidate";
import APIUtils from "../../utils/apiUtils";
import CriticalSkill from "./cardInfo/criticalSkill/CriticalSkill";
import CheckSkillGraph from "./checkSkill/CheckSkillGraph";
import DisprzContent from "./disprzContent/DisprzContent";
import Footer from "./footer/Footer";
import Graph2 from "./graph/Graph2";
import Pyramid from "./graph/Pyramid";
import RatingPage from "./rating/RatingPage";
import PrivacyComponent from "./privacy/PrivacyComponent";
import HubspotContactForm from './HubspotContactForm';

const color = ["#735DE5", "#5D289F", "#83AD52", "#3274EC"];
const colorBg = ["#EAE7FB", "#E7DFF1", "#ECF3E5", "#E0EAFC"];
const polularJob = [3, 12, 17, 20, 21, 23];

const Intelligence = (props) => {
  const [menuJob, setMenuJob] = useState([]);
  const [selectedMenuJob, setSelectedMenuJob] = useState();
  const [menuJobSelectedMain, setMenuJobSelectedMain] = useState();

  const [popupEmailShow, setPopupEmailShow] = useState(false);

  const [topSkills, setTopSkills] = useState([]);
  const [topSkillSelected, setTopSkillSelected] = useState();

  const [decliningSkills, setDecliningSkills] = useState([]);
  const [decliningSkillSelected, setDecliningSkillSelected] = useState();

  const [dataGraph1, setDataGraph1] = useState();
  const [dataGraph2, setDataGraph2] = useState();

  const [loadingTopSkill, setLoadingTopSkill] = useState(false);
  const [loadingDecliningSkill, setLoadingDecliningSkill] = useState(false);

  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");

  const [email, setEmail] = useState("");
  const [isValidateEmail, setValidateEmail] = useState(true);
  const [name, setName] = useState("");
  const [isValidateName, setValidateName] = useState(true);
  // const [desTxt, setDesTxt] = useState("");
  const [searchJob, setSearchJob] = useState("");
  const [searchData, setSearchData] = useState([]);
  const [loadingStatus, setLoadingStatus] = useState(false);

  const [dataVideo, setDataVideo] = useState();

  const [dataContent, setDataContent] = useState();
  const [dataContentSkill, setDataContentSkill] = useState();

  const criticalRef = useRef();
  const pyramidRef = useRef();
  const refPyramid = useRef();
  const params = new URLSearchParams(window.location.search);

  useEffect(() => {
    const fetchData = async () => {
      let end = moment().format("YYYY-MM-DD hh:mm:ss");
      let start = new Date(end);

      start = moment(start.setMonth(start.getMonth() - 12)).format(
        "YYYY-MM-DD hh:mm:ss"
      );
      if (
        new Date(start) < new Date(moment("2021-09-01").format("YYYY-MM-DD"))
      ) {
        start = moment("2021-09-01").format("YYYY-MM-DD hh:mm:ss");
      } else {
      }

      setStartDate(start);
      setEndDate(end);
      // }
    };

    fetchData().catch(console.error);
  }, []);

  const getDataJob = useCallback(
    async (e) => {
      setMenuJobSelectedMain(e);
      setTopSkills([]);
      setDecliningSkills([]);
      setLoadingTopSkill(true);
      setLoadingDecliningSkill(true);
      pyramidRef.current.clearData();
      setDataContent();

      setTimeout(() => {
        criticalRef.current.scrollIntoView();
      }, 500);
      //
      // get Top skills, declining skills
      const url =
        BASE_URL +
        `/jobintelligence/v1/Sectors/${3}/Occupations/${
          e?.id
        }/getMonthWiseTopSkills`;

      const body = {
        startDate: moment(startDate).format("YYYY-MM-DD") + " 00:00:00",
        endDate: moment(endDate).format("YYYY-MM-DD hh:mm:ss"),
      };

      const promiseTopSkill = new Promise((resolve, reject) => {
        const data = APIUtils.post(url, { body: body });
        resolve(data);
      });

      promiseTopSkill.then((data) => {
        if (data?.data?.body?.top_skills?.length > 4) {
          setTopSkills(
            data?.data?.body?.top_skills?.slice(
              0,
              -(data?.data?.body?.top_skills?.length - 4)
            )
          ); // api return 5 skills but just need 4
        } else {
          setTopSkills(data?.data?.body?.top_skills);
        }
        setLoadingTopSkill(false);

        setTopSkillSelected(data?.data?.body?.top_skills?.[0]);
      });

      // get declining skill
      const urlDeclining =
        BASE_URL +
        `/jobintelligence/v1/Sectors/${3}/Occupations/${
          e?.id
        }/getDecliningSkills`;

      const promiseDecliningSkill = new Promise((resolve, reject) => {
        const data = APIUtils.post(urlDeclining, {
          body: body,
        });
        resolve(data);
      });

      promiseDecliningSkill.then((dataDecliningSkill) => {
        if (dataDecliningSkill?.data?.body?.top_skills?.length > 4) {
          setDecliningSkills(
            dataDecliningSkill?.data?.body?.top_skills?.slice(
              0,
              -(dataDecliningSkill?.data?.body?.top_skills?.length - 4)
            ) // api return 5 skills but just need 4
          );
        } else {
          setDecliningSkills(dataDecliningSkill?.data?.body?.top_skills);
        }

        setDecliningSkillSelected(
          dataDecliningSkill?.data?.body?.top_skills?.[0]
        );
      });

      setLoadingDecliningSkill(false);

      const urlContent =
        BASE_URL +
        `/jobintelligence/v1/Sectors/${3}/Occupations/${e?.id}/getContentCount`;

      const promiseContent = new Promise((resolve, reject) => {
        const data = APIUtils.post(urlContent);
        resolve(data);
      });

      promiseContent.then((data) => {
        setDataContent(data);
      });

      const urlContentSkill =
        BASE_URL + `/jobintelligence/v1/getContentSKillData`;

      const promiseContentSkill = new Promise((resolve, reject) => {
        const data = APIUtils.post(urlContentSkill);
        resolve(data);
      });

      promiseContentSkill.then((data) => {
        setDataContentSkill(data);
      });

      if (!dataVideo) {
        const urlVideo =
          BASE_URL +
          `/jobintelligence/v1/getLXPvideoFiles?sectorID=${3}&occupationID=${
            e?.id
          }`;

        const promiseVideoUrl = new Promise((resolve, reject) => {
          const data = APIUtils.post(urlVideo, {});
          resolve(data);
        });

        promiseVideoUrl.then((data) => {
          setDataVideo(data?.data?.body?.url);
        });
      }
    },
    [startDate, endDate]
  );

  const onMenuJobItemClicked = useCallback(
    (e) => async () => {
      setSelectedMenuJob(e);
    },
    []
  );

  const onCheckSkillClicked = useCallback(
    (e) => async () => {
      setSelectedMenuJob(e);
      const submitTime = localStorage.getItem("submitTime");
      if (submitTime) {
        if (checkTime()) {
          getDataJob(e);
        }
      } else {
        if (email) {
          getDataJob(e);
        } else {
          setPopupEmailShow(true);
        }
      }
    },
    [email, startDate, endDate]
  );

  // useEffect(() => {
  //   // get Top skill graph
  //   if (topSkillSelected && selectedMenuJob) {
  //     const url =
  //       BASE_URL +
  //       `/jobintelligence/v1/Sectors/${3}/Occupations/${
  //         selectedMenuJob?.id
  //       }/Skill/${topSkillSelected[2]}/getSkillGraph`;
  //     const body = {
  //       startDate,
  //       endDate,
  //     };

  //     const promiseDataGraph = new Promise((resolve, reject) => {
  //       const data = APIUtils.post(url, { body: body });
  //       resolve(data);
  //     });

  //     promiseDataGraph.then((data) => {
  //       setDataGraph1(data?.data?.body?.skill_growth);
  //     });
  //   }
  // }, [topSkillSelected, selectedMenuJob, startDate, endDate]);

  // useEffect(() => {
  //   // get declining
  //   if (decliningSkillSelected && selectedMenuJob) {
  //     const url =
  //       BASE_URL +
  //       `/jobintelligence/v1/Sectors/${3}/Occupations/${
  //         selectedMenuJob?.id
  //       }/Skill/${decliningSkillSelected[2]}/getSkillGraph`;
  //     const body = {
  //       startDate,
  //       endDate,
  //     };
  //     const promiseDataGraph = new Promise((resolve, reject) => {
  //       const data = APIUtils.post(url, { body: body });
  //       resolve(data);
  //     });

  //     promiseDataGraph.then((data) => {
  //       setDataGraph2(data?.data?.body?.skill_growth);
  //     });
  //   }
  // }, [decliningSkillSelected, selectedMenuJob, startDate, endDate]);

  const onCardInfoClicked = useCallback(
    (value, key) => async () => {
      setDataGraph1();
      let tmp = [...value];
      tmp.push(key);
      setTopSkillSelected(tmp);
    },
    []
  );

  // const onCardDecliningClicked = useCallback(
  //   (value, key) => async () => {
  //     setDataGraph2();
  //     let tmp = [...value];
  //     tmp.push(key);
  //     setDecliningSkillSelected(tmp);
  //   },
  //   []
  // );

  const onChangeEmail = useCallback((event) => {
    setEmail(event.target.value);
  }, []);

  const onChangeName = useCallback((event) => {
    setName(event.target.value);
  }, []);

  // const onChangeDes = useCallback((event) => {
  //   setDesTxt(event.target.value);
  // }, []);

  const checkTime = useCallback(() => {
    const submitTime = localStorage.getItem("submitTime");
    const currentTime = moment().format();

    var diffMs = new Date(currentTime) - new Date(submitTime);
    const minDiff = Math.round(diffMs * 0.000016667);
    if (minDiff >= 0 && minDiff <= 30) {
      return true;
    } else {
      setTopSkills([]);
      setDecliningSkills([]);
      setPopupEmailShow(true);
      setEmail("");
      setName("");
      return false;
    }
  }, []);

  const onSubmitEmail = useCallback(
    async (e) => {
      const isValidate = validateEmail(email);
      const isValidateName = validateName(name);

      setValidateEmail(isValidate);
      setValidateName(isValidateName);
      if (isValidate && isValidateName) {
        const body = {
          email,
          name,
        };

        const url =
          BASE_URL +
          `/jobintelligence/v1/Integrations/Name/${encodeURIComponent(
            name
          )}/Email/${encodeURIComponent(
            email
          )}/lxp/none/feedback/none/getIntegrationStatus`;
        setLoadingStatus(true);
        const data = await APIUtils.post(url, {});
        if (data?.data?.status === 400) {
          setValidateName(false);
          setLoadingStatus(false);
        } else if (data?.data?.status === 401) {
          setValidateEmail(false);
          setLoadingStatus(false);
        } else {
          setValidateName(true);
          setValidateEmail(true);
          setLoadingStatus(false);
          setPopupEmailShow(false);
          getDataJob(selectedMenuJob);
          const submitTime = moment().format();
          localStorage.setItem("submitTime", submitTime);
          localStorage.setItem("userInfo", JSON.stringify(body));
        }
      } else {
        e.preventDefault();
      }
    },
    [email, selectedMenuJob, name]
  );

  const onChangeSearchJob = useCallback(
    (event) => {
      setSearchJob(event.target.value);
      let filteredArray = menuJob.filter((str) => {
        return (
          str.occupation_name
            .toLowerCase()
            .indexOf(event.target.value.toLowerCase()) >= 0
        );
      });

      setSearchData(filteredArray);
    },
    [menuJob]
  );

  const closeModalInfo = useCallback(() => {
    setPopupEmailShow(false);
    setName("");
    setEmail("");
    // setDesTxt("");
  }, []);

  const onContactFormSubmit = () => {
      setPopupEmailShow(false);
      getDataJob(selectedMenuJob);
  };

  return (
    <div className="main-page">
      <div>
        <img
          src={wave}
          alt="Thumb"
          style={{ position: "absolute", width: "100%" }}
        />
        <img
          src={snowflake}
          alt={"boohoo"}
          style={{ position: "absolute", top: 200 }}
          className={"snowflake"}
        />
        <div
          style={{
            backgroundColor: "white",
          }}
          className="gap-component row g-0"
        >
          <div className="col-lg-5 col-md-6 col-sm-12" id="left-view">
            <h2 className="txt-title">
              how can you prepare your techforce for the future? <br />
            </h2>
            <h5 className="txt-description">
              it starts with skills intelligence.
            </h5>
            <h5 className="txt-description2">
              <br />
              The tech landscape is changing rapidly, and businesses are
              constantly looking to stay competitive by investing in tech
              skills. <br /> <br />
              Know what in-demand tech skills and content intelligence is needed
              to build a competent workforce.
              <br />
              <br />
              {/* <div style={{ fontStyle: "italic" }}> */}
              Select a job family below to get started
              {/* </div> */}
            </h5>
          </div>
          <div className="col-lg-6 col-md-6 col-sm-12">
            <img src={posterImg} alt="Thumb" className="img-header" />
          </div>
        </div>
        <div
          className="gap-component"
          style={{
            backgroundColor: "transparent",
          }}
        >
          <div style={{ display: "flex", alignItems: "center" }}>
            <div>
              <h2 className="txt-sub-title">check skills intelligence</h2>
            </div>
            <div className="popular-des">
              <img src={star} alt="Thumb" /> popular job family
            </div>
          </div>
          <div
            style={{
              // fontWeight: 300,
              // fontSize: 13,
              // color: "rgba(0, 0, 0, 0.8)",
              // width: 520,
              marginBottom: 30,
            }}
          >
            The analysis comes from advanced data processing, drawing insights
            from thousands of job postings across different roles globally.
          </div>

          <CheckSkillGraph
            searchJob={searchJob}
            onChangeSearchJob={onChangeSearchJob}
            menuJob={menuJob}
            searchData={searchData}
            selectedMenuJob={selectedMenuJob}
            // onMenuJobItemClicked={onMenuJobItemClicked}
            polularJob={polularJob}
            onCheckSkillClicked={onCheckSkillClicked}
          />
        </div>
        <div ref={criticalRef}>
          <CriticalSkill data={menuJobSelectedMain} video={dataVideo} />
        </div>

        {menuJobSelectedMain && (
          <div className="gap-component">
            <h2 className="txt-sub-title">
              {/* skill demand for - {menuJobSelectedMain?.occupation} (vs last
                year) */}
              most in-demand skills
            </h2>
            Skills demand compares the increase or decrease in demand for a
            particular skill as <b>compared to the past 12 months.</b>
          </div>
        )}

        <div className="row g-0 justify-content-around">
          <div className="col-lg-6 col-md-12 mb-sm-5">
            {topSkills?.length !== 0 && (
              <Graph2
                topSkills={topSkills}
                onCardInfoClicked={onCardInfoClicked}
                color={color}
                colorBg={colorBg}
                topSkillSelected={topSkillSelected}
                dataGraph={dataGraph1}
                title="Trending"
                title2="Skills"
              />
            )}
          </div>
          <div className="col-lg-6 col-md-12 mb-sm-5">
            {loadingDecliningSkill && !loadingTopSkill ? (
              <div style={{ marginLeft: "45%", marginTop: "10%" }}>
                <ReactLoading
                  type={"bubbles"}
                  color={"rgba(0, 0, 0, 0.12)"}
                  height={"10%"}
                  width={"10%"}
                />
              </div>
            ) : (
              decliningSkills?.length !== 0 && (
                <Graph2
                  topSkills={decliningSkills}
                  // onCardInfoClicked={onCardDecliningClicked}
                  color={color}
                  colorBg={colorBg}
                  topSkillSelected={decliningSkillSelected}
                  dataGraph={dataGraph2}
                  title="Declining"
                  title2="Skills"
                  rightSide
                />
              )
            )}
          </div>
        </div>
        {menuJobSelectedMain && (
          <div className={"gap-component"}>
            <h2 className="txt-sub-title">content intelligence</h2>
            Content intelligence showcases the number of relevant pieces of
            learning content curated from paid and free content providers.
          </div>
        )}
        <DisprzContent
          title={"paid content from popular MOOCs"}
          menuJobSelectedMain={menuJobSelectedMain}
          dataContent={dataContent}
          dataContentSkill={dataContentSkill}
        />
        <DisprzContent
          title={"open content curated by disprz"}
          menuJobSelectedMain={menuJobSelectedMain}
          isFree
          dataContent={dataContent}
          video={dataVideo}
          dataContentSkill={dataContentSkill}
        />
        <div ref={refPyramid}>
          <Pyramid ref={pyramidRef} data={menuJobSelectedMain} />
        </div>
        <JobTitle menuJobSelectedMain={menuJobSelectedMain} />
        <Modal show={popupEmailShow} centered>
          <div style={{ paddingInline: 50 }}>
            <div
              style={{
                position: "absolute",
                top: 10,
                right: -30,
              }}
            >
              <img
                src={closeIcon}
                alt=""
                onClick={closeModalInfo}
                // style={{
                //   width: 28,
                //   height: 28,
                // }}
                style={{ cursor: "pointer" }}
              />
            </div>
            <h2
              className="txt-sub-title popup"
              style={{ textAlign: "center", paddingTop: 40 }}
            >
              generate your tech skills report now
            </h2>
            <div className="txt-des-popup">
              enter the details below and get access to disprz skills
              intelligence that will allow your team to grow
            </div>
            <HubspotContactForm
                region="na1"
                portalId="20091681"
                formId="8a6545bd-9b16-4ce4-998f-56b9987f2b79"
                onContactFormSubmit={onContactFormSubmit}
            />
              {/*iframe name="votar" style={{ display: "none" }}></iframe>
            <form
              action="https://info.disprz.com/l/975733/2022-10-06/22jr3"
              onSubmit={onSubmitEmail}
              target="votar"
            >
              <div id="input-des">Name</div>
              <input
                className="popup-input"
                type="text"
                name="Name"
                id="Name"
                placeholder="Enter Name"
                value={name}
                onChange={onChangeName}
                required
              />
              <div id="input-des">Work Email</div>
              <input
                className="popup-input"
                type="text"
                name="Email"
                id="Email"
                placeholder="Enter Email Id"
                onChange={onChangeEmail}
                value={email}
                required
              />
              <input
                type="hidden"
                id="utm_campaign"
                name="utm_campaign"
                value={params.get("utm_campaign")}
              />
              <input
                type="hidden"
                id="utm_medium"
                name="utm_medium"
                value={params.get("utm_medium")}
              />
              <input
                type="hidden"
                id="utm_source"
                name="utm_source"
                value={params.get("utm_source")}
              />
              <input type="hidden" id="Referrer" name="Referrer" value={" "} />
              {!isValidateName ? (
                <div id="input-error">Please check your input</div>
              ) : (
                !isValidateEmail && (
                  <div id="input-error">
                    Please use your company email address
                  </div>
                )
              )}
              <div
                style={{
                  display: "flex",
                  alignItem: "center",
                  justifyContent: "center",
                  marginTop: 20,
                  paddingBottom: 29,
                }}
              >
                <button
                  className={`button-popup ${
                    email && name ? "popup-enable" : ""
                  }`}
                  // onClick={onSubmitEmail}
                  disabled={email && name ? false : true}
                  style={{
                    width: 80,
                    height: 32,
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                  type="submit"
                >
                  {loadingStatus ? (
                    <div
                      style={{
                        marginTop: 10,
                        position: "absolute",
                      }}
                    >
                      <ReactLoading
                        type={"spin"}
                        color={"white"}
                        height={32}
                        width={20}
                      />
                    </div>
                  ) : (
                    <>submit</>
                  )}
                </button>
              </div>
            </form>*/}
          </div>
        </Modal>
      </div>
      {/* <img
        src={wave2}
        alt="Thumb"
        style={{ position: "absolute", width: "100%",bottom:10, zIndex: -9 }}
      /> */}
      {menuJobSelectedMain && <RatingPage />}

      <Footer menuJobSelectedMain={menuJobSelectedMain} />

      <PrivacyComponent menuJobSelectedMain={menuJobSelectedMain} />
    </div>
  );
};

export default React.memo(Intelligence);
