import React, { useCallback, useRef, useState } from "react";
import { Modal } from "react-bootstrap";
import "../../../App.css";
import ModalGetPhone from "../../../component/modalGetPhone/ModalGetPhone";
import { BASE_URL } from "../../../constant/apiUrl";
import APIUtils from "../../../utils/apiUtils";

function Footer(props) {
  // const openLink = (url) =>
  //   window.open("https://disprz.com/intl/select-a-preview/", "_self")?.focus();

  const timer = useRef(null);

  const [showModalPhone, setShowModalPhone] = useState(false);

  const onClickRequest = useCallback(async () => {
    const tmp = localStorage.getItem("userInfo");
    const userInfo = JSON.parse(tmp);
    const url =
      BASE_URL +
      `/jobintelligence/v1/Integrations/Name/${encodeURIComponent(
        userInfo?.name
      )}/Email/${encodeURIComponent(
        userInfo?.email
      )}/lxp/Yes/feedback/none/getIntegrationStatus`;

    clearTimeout(timer.current);
    setShowModalPhone(true);
    timer.current = setTimeout(() => {
      setShowModalPhone(false);
    }, 5000);
    const data = await APIUtils.post(url, {});
  }, []);

  const onHideModal = useCallback(() => {
    setShowModalPhone(false);
  }, []);

  const onChangePhone = useCallback(() => {
    clearTimeout(timer.current);
  }, []);

  return (
    <>
      <div
        className="footer"
        // style={props.menuJobSelectedMain ? { marginTop: 0 } : { marginTop: 87 }}
      >
        {props.menuJobSelectedMain && (
          <div style={{ paddingInline: 60, paddingTop: 40 }}>
            Put these insights into action. Build the right skills with disprz.
            <div className={"footer-des"}>
              1. Benchmark your workforce’s skills
              <br /> 2. Set them on personalised learning paths that meet your
              skill requirements
              <br /> 3. Stay up to date on your their progress
            </div>
            <div className="button-footer-container">
              <button href="" onClick={onClickRequest} className="btn-preview">
                book a consultation
              </button>
              {/* <button className="btn-view-graph">fill your skill graph</button> */}
            </div>
          </div>
        )}
        <Modal size={"lg"} show={showModalPhone} centered onHide={onHideModal}>
          <ModalGetPhone onChangePhone={onChangePhone} />
        </Modal>
      </div>
      {props.menuJobSelectedMain && (
        <div>
          <div onClick={onClickRequest} className="sticky-book">
            Book A Consultation
          </div>
        </div>
      )}
    </>
  );
}

export default React.memo(Footer);
