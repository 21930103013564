import React from "react";
import cardGraph from "../../../assets/img/cardGraph.svg";
import cardDes from "../../../assets/img/cardDes.svg";
import cardGraph2 from "../../../assets/img/cardGraph2.svg";
import cardDes2 from "../../../assets/img/cardDes2.svg";
import cardGraph3 from "../../../assets/img/cardGraph3.svg";
import cardDes3 from "../../../assets/img/cardDes3.svg";
import cardGraph4 from "../../../assets/img/cardGraph4.svg";
import cardDes4 from "../../../assets/img/cardDes4.svg";
import cardGraphRight from "../../../assets/img/cardGraphRight.svg";
import cardGraphRight2 from "../../../assets/img/cardGraphRight2.svg";
import cardGraphRight3 from "../../../assets/img/cardGraphRight3.svg";
import cardGraphRight4 from "../../../assets/img/cardGraphRight4.svg";
import upTrend from "../../../assets/img/upTrend.svg";
import downTrend from "../../../assets/img/downTrend.svg";

function Graph2(props) {
  return (
    <div
      className="graph2-container"
      style={props.rightSide ? { transform: "rotate(180deg)" } : {}}
    >
      <div className="circle-right">
        <div
          className="title-chart-container"
          style={
            props.rightSide
              ? { transform: "rotate(180deg)", textAlign: "right" }
              : {}
          }
        >
          {props.title} <br /> {props.title2}
        </div>
        <div className="circle-right-fade"></div>
        <div className="chart-data-container">
          <img
            src={props.rightSide ? cardGraphRight : cardGraph}
            alt="Thumb"
            className="card-graph-chart"
            color="red"
          />
          <img src={cardDes} alt="Thumb" className="card-des-chart" />

          {props.rightSide ? (
            <div
              className="txt-des-chart right"
              style={props.rightSide ? { transform: "rotate(180deg)" } : {}}
            >
              <img src={props.rightSide ? downTrend : upTrend} alt="Thumb" />
              <div className="txt-percent right">
                {props.topSkills?.[3]?.[1]
                  ? Math.round(props.topSkills?.[3]?.[1]) + "%"
                  : "-"}
              </div>
            </div>
          ) : (
            <div
              className="txt-des-chart"
              style={props.rightSide ? { transform: "rotate(180deg)" } : {}}
            >
              <div className="txt-percent">
                {props.topSkills?.[0]?.[1]
                  ? Math.round(props.topSkills?.[0]?.[1]) + "%"
                  : "-"}
              </div>
              <img
                src={props.rightSide ? downTrend : upTrend}
                alt="Thumb"
                // className="card-graph-chart2"
              />
            </div>
          )}
          <div
            className={
              props.rightSide ? "txt-skill-chart-right" : "txt-skill-chart"
            }
            style={props.rightSide ? { transform: "rotate(180deg)" } : {}}
          >
            {props.rightSide
              ? props.topSkills?.[3]?.[0] || "-"
              : props.topSkills?.[0]?.[0] || "-"}
          </div>
        </div>
        <div className="chart-data-container2">
          <img
            src={props.rightSide ? cardGraphRight2 : cardGraph2}
            alt="Thumb"
            className="card-graph-chart2"
          />
          <img src={cardDes2} alt="Thumb" className="card-des-chart2" />

          {props.rightSide ? (
            <div
              className="txt-des-chart2 right"
              style={props.rightSide ? { transform: "rotate(180deg)" } : {}}
            >
              <img src={props.rightSide ? downTrend : upTrend} alt="Thumb" />
              <div className="txt-percent right">
                {props.topSkills?.[2]?.[1]
                  ? Math.round(props.topSkills?.[2]?.[1]) + "%"
                  : "-"}
              </div>
            </div>
          ) : (
            <div
              className="txt-des-chart2"
              style={props.rightSide ? { transform: "rotate(180deg)" } : {}}
            >
              <div className="txt-percent">
                {props.topSkills?.[1]?.[1]
                  ? Math.round(props.topSkills?.[1]?.[1]) + "%"
                  : "-"}
              </div>
              <img
                src={props.rightSide ? downTrend : upTrend}
                alt="Thumb"
                // className="card-graph-chart2"
              />
            </div>
          )}
          <div
            className={
              props.rightSide ? "txt-skill-chart2-right" : "txt-skill-chart2"
            }
            style={props.rightSide ? { transform: "rotate(180deg)" } : {}}
          >
            {props.rightSide
              ? props.topSkills?.[2]?.[0] || "-"
              : props.topSkills?.[1]?.[0] || "-"}
          </div>
        </div>
        <div className="chart-data-container3">
          <img
            src={props.rightSide ? cardGraphRight3 : cardGraph3}
            alt="Thumb"
            className="card-graph-chart2"
          />
          <img src={cardDes3} alt="Thumb" className="card-des-chart2" />

          {props.rightSide ? (
            <div
              className="txt-des-chart2 right"
              style={props.rightSide ? { transform: "rotate(180deg)" } : {}}
            >
              <img src={props.rightSide ? downTrend : upTrend} alt="Thumb" />
              <div className="txt-percent right">
                {props.topSkills?.[1]?.[1]
                  ? Math.round(props.topSkills?.[1]?.[1]) + "%"
                  : "-"}
              </div>
            </div>
          ) : (
            <div
              className="txt-des-chart2"
              style={props.rightSide ? { transform: "rotate(180deg)" } : {}}
            >
              <div className="txt-percent">
                {props.topSkills?.[2]?.[1]
                  ? Math.round(props.topSkills?.[2]?.[1]) + "%"
                  : "-"}
              </div>
              <img src={props.rightSide ? downTrend : upTrend} alt="Thumb" />
            </div>
          )}
          <div
            className={
              props.rightSide ? "txt-skill-chart2-right" : "txt-skill-chart2"
            }
            style={props.rightSide ? { transform: "rotate(180deg)" } : {}}
          >
            {props.rightSide
              ? props.topSkills?.[1]?.[0] || "-"
              : props.topSkills?.[2]?.[0] || "-"}
          </div>
        </div>
        <div className="chart-data-container4">
          <img
            src={props.rightSide ? cardGraphRight4 : cardGraph4}
            alt="Thumb"
            className="card-graph-chart"
          />
          <img src={cardDes4} alt="Thumb" className="card-des-chart" />

          {props.rightSide ? (
            <div
              className="txt-des-chart right"
              style={props.rightSide ? { transform: "rotate(180deg)" } : {}}
            >
              <img src={props.rightSide ? downTrend : upTrend} alt="Thumb" />
              <div className="txt-percent right">
                {props.topSkills?.[0]?.[1]
                  ? Math.round(props.topSkills?.[0]?.[1]) + "%"
                  : "-"}
              </div>
            </div>
          ) : (
            <div
              className="txt-des-chart"
              style={props.rightSide ? { transform: "rotate(180deg)" } : {}}
            >
              <div className="txt-percent">
                {props.topSkills?.[3]?.[1]
                  ? Math.round(props.topSkills?.[3]?.[1]) + "%"
                  : "-"}
              </div>
              <img src={props.rightSide ? downTrend : upTrend} alt="Thumb" />
            </div>
          )}
          <div
            className={
              props.rightSide ? "txt-skill-chart-right" : "txt-skill-chart"
            }
            style={props.rightSide ? { transform: "rotate(180deg)" } : {}}
          >
            {props.rightSide
              ? props.topSkills?.[0]?.[0] || "-"
              : props.topSkills?.[3]?.[0] || "-"}
          </div>
        </div>
      </div>
    </div>
  );
}

export default React.memo(Graph2);
