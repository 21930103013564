import "./App.css";
import Intelligence from "./screen/intelligence/Intelligence";
import headerLogo from "./assets/img/headerLogo.png";
import ReactGA from "react-ga";

const TRACKING_ID = "UA-146471275-1"; // OUR_TRACKING_ID
ReactGA.initialize(TRACKING_ID, {
  gaOptions: {
    cookieName: "gaCookie",
    cookieDomain: "skillsintelligence.disprz.com",
    cookieExpires: 60 * 60 * 24 * 28,
    cookieUpdate: "false",
    cookieFlags: "SameSite=None; Secure",
  },
});

function App() {
  const openLink = (url) =>
    window.open("https://disprz.com/", "_self")?.focus();
  return (
    <div>
      <div className="header-page">
        <img
          onClick={openLink}
          src={headerLogo}
          alt="img"
          className="header-icon"
        />
      </div>
      <Intelligence />
    </div>
  );
}

export default App;
