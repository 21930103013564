import React, { useCallback, useState } from "react";
import "./DisprzContent.css";
import { dataImgContent, dataTitleContent } from "../../../utils/Constant";
import ModalContent from "./ModalContent";
import ReactLoading from "react-loading";

const ContentItem = (props) => {
  const [showContentModal, setShowContentModal] = useState(false);
  const [dataModal, setDataModal] = useState();
  const [itemClicked, setItemClicked] = useState();

  const onValueClicked = useCallback(
    (item, itemClickedData) => () => {
      // popup
      if (props.dataContent) {
        let tmpArr = [];
        for (
          let i = 0;
          i < Object.keys(props.dataContent.skill_id).length;
          i++
        ) {
          if (props.dataContent.skill_id[i] === item.skill_id) {
            tmpArr.push(i);
          }
        }

        let arr = [];
        for (let i = 0; i < tmpArr.length; i++) {
          let tmp = {};
          for (const [key] of Object.entries(props.dataContent)) {
            tmp[key] = props.dataContent[key][tmpArr[i]];
          }
          arr.push(tmp);
        }

        let arrDataContent = [];
        for (let i = 0; i < arr.length; i++) {
          if (
            arr[i].content_provider
              .toLowerCase()
              .includes(dataTitleContent[itemClickedData.name].toLowerCase())
          ) {
            arrDataContent.push(arr[i]);
          }
        }
        setDataModal(arrDataContent);
      }
      setItemClicked(itemClickedData);
      setShowContentModal(true);
    },
    [props.dataContent]
  );

  const handleCloseModal = useCallback(() => {
    setShowContentModal(false);
  }, []);

  return props.data?.data ? (
    <div className="content-card-container">
      <div>
        <div className="content-card-name title">Skill</div>
        {props.data?.data.map((i, key) => {
          return (
            <div key={key} style={{ display: "flex" }}>
              <div className="content-card-name">{i.skill_name}</div>
            </div>
          );
        })}
      </div>
      <div style={{ overflowX: "auto" }}>
        <div style={{ display: "flex" }}>
          {props.data?.data?.[0]?.dataPro?.map((item, key) => {
            return (
              <div key={key} className="content-card-value title">
                <img
                  className="content-img"
                  src={dataImgContent[item.name]}
                  alt="Thumb"
                />
                {/* <p className="content-txt">{item.name}</p> */}
              </div>
            );
          })}
        </div>
        {props.data?.data.map((i, key) => {
          return (
            <div key={key} style={{ display: "flex" }}>
              {i?.dataPro?.map((item, key2) => {
                return (
                  <div
                    key={key2}
                    className="content-card-value"
                    onClick={item.value ? onValueClicked(i, item) : () => {}}
                    style={item.value ? { cursor: "pointer" } : {}}
                  >
                    <p className="content-txt">{item.value}</p>
                  </div>
                );
              })}
            </div>
          );
        })}
      </div>
      <ModalContent
        show={showContentModal}
        onHide={handleCloseModal}
        title={
          <div>
            paid content from{" "}
            <img
              className="content-img"
              src={dataImgContent[itemClicked?.name]}
              alt="Thumb"
              // style={}
            />
          </div>
        }
        dataModal={dataModal}
        lengthList={itemClicked?.value || 0}
      />
    </div>
  ) : (
    <div style={{ marginLeft: "45%" }}>
      <ReactLoading
        type={"bubbles"}
        color={"rgba(0, 0, 0, 0.12)"}
        height={"10%"}
        width={"10%"}
      />
    </div>
  );
};

export default React.memo(ContentItem);
