import React, { useCallback, useEffect, useState } from "react";
import likeIcon from "../../../assets/img/like.svg";
import dislikeIcon from "../../../assets/img/dislike.svg";
import likeEnableIcon from "../../../assets/img/likeEnable.svg";
import checkIcon from "../../../assets/img/check.svg";
import dislikeEnableIcon from "../../../assets/img/dislikeEnable.svg";
import ratingIcon from "../../../assets/img/rating.svg";
import wave3 from "../../../assets/img/wave3.svg";
import { BASE_URL } from "../../../constant/apiUrl";
import APIUtils from "../../../utils/apiUtils";

function RatingPage(props) {
  const [isFeedbacked, setIsFeedbacked] = useState();

  useEffect(() => {
    const tmp = localStorage.getItem("userInfo");
    const userInfo = JSON.parse(tmp);

    setIsFeedbacked(userInfo?.feedback ? true : false);
  }, []);

  const onFeedbackClicked = useCallback(
    (like) => async () => {
      let value = like ? "Yes" : "No";
      const tmp = localStorage.getItem("userInfo");
      const userInfo = JSON.parse(tmp);
      setIsFeedbacked(true);
      const url =
        BASE_URL +
        `/jobintelligence/v1/Integrations/Name/${encodeURIComponent(
          userInfo?.name
        )}/Email/${encodeURIComponent(
          userInfo?.email
        )}/lxp/None/feedback/${value}/getIntegrationStatus`;
      const data = await APIUtils.post(url, {});
      userInfo.feedback = value;
      const temp = await localStorage.setItem(
        "userInfo",
        JSON.stringify(userInfo)
      );
      let tmp2 = localStorage.getItem("userInfo");
      tmp2 = JSON.parse(tmp2);
    },
    []
  );

  return (
    <div>
      {/* <img
        src={wave3}
        alt="Thumb"
        style={{ position: "absolute", width: "100%", zIndex: -9 }}
      /> */}
      <div
        className="gap-component"
        style={{
          backgroundColor: "transparent",
          backgroundImage: `url(${wave3})`,
        }}
      >
        <div className="row">
          {isFeedbacked ? (
            <div className="col-6 response-feedback">
              <img src={checkIcon} alt="" className="check-icon-rating" />
              Thanks for your valuable feedback.
            </div>
          ) : (
            <div className="col-6">
              <h2 className="txt-sub-title rating">
                Did you find the skills information useful and relevant?
              </h2>

              <div style={{ display: "flex", marginTop: 20 }}>
                <div
                  onClick={onFeedbackClicked(true)}
                  className="container-icon-feedback"
                >
                  <div>
                    <img
                      src={isFeedbacked === "Yes" ? likeEnableIcon : likeIcon}
                      alt=""
                      className={"icon-feedback"}
                    />
                  </div>
                  <div className="container-icon-feedback-overplay">
                    <img
                      src={likeEnableIcon}
                      alt=""
                      className={"icon-feedback"}
                    />
                  </div>
                </div>
                <div
                  onClick={onFeedbackClicked(false)}
                  className="container-icon-feedback"
                >
                  <img
                    src={
                      isFeedbacked === "No" ? dislikeEnableIcon : dislikeIcon
                    }
                    alt=""
                    className={"icon-feedback"}
                  />
                  <div className="container-icon-feedback-overplay">
                    <img
                      src={dislikeEnableIcon}
                      alt=""
                      className={"icon-feedback"}
                    />
                  </div>
                </div>
              </div>
            </div>
          )}
          <div className="col-6 rating-img-view">
            <img src={ratingIcon} alt="" className="rating-icon" />
          </div>
        </div>
      </div>
      <div
        style={{
          backgroundColor: "rgba(106, 28, 166, 0.08)",
          opacity: 0.6,
          height: 100,
        }}
      />
    </div>
  );
}

export default React.memo(RatingPage);
