import React from "react";
import "./CheckSkill.css";
import star from "../../../assets/img/star.svg";

function CheckSkillCard(props) {
  return (
    <div className={"card-container"}>
      <div className="card-item-title">{props.data?.track_name}</div>

      {props.data?.data?.map((item, i) => {
        return (
          <button
            key={i}
            className={
              item?.selected ? "card-item-list selected" : "card-item-list"
            }
            onClick={props.onOccupationSelect(item, props.trackKey, i)}
          >
            <div>{item.occupation}</div>
            {item?.isPopular && (
              <img
                src={star}
                alt="Thumb"
                style={{
                  position: "absolute",
                  right: 5,
                  top: -12,
                }}
              />
            )}
          </button>
        );
      })}
    </div>
  );
}

export default React.memo(CheckSkillCard);
