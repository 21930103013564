import React, { useEffect, useState } from "react";

import { BASE_URL } from "../../constant/apiUrl";
import APIUtils from "../../utils/apiUtils";
import { wordCloud } from "../../utils/Constant";

const JobTitle = (props) => {
  // const [jobTitle, setJobTitle] = useState();
  // useEffect(() => {
  //   if (props.menuJobSelectedMain) {
  //     setJobTitle();
  //     const urlJobTitle =
  //       BASE_URL +
  //       `/jobintelligence/v1/Sectors/${3}/Occupations/${
  //         props?.menuJobSelectedMain?.id
  //       }/getWordCloud`;

  //     const promiseJobTitle = new Promise((resolve, reject) => {
  //       const data = APIUtils.post(urlJobTitle, {
  //         // body: body,
  //       });
  //       resolve(data);
  //     });

  //     promiseJobTitle.then((dataJobTitle) => {
  //       setJobTitle(dataJobTitle);
  //     });
  //   }
  // }, [props.menuJobSelectedMain]);

  return (
    props?.menuJobSelectedMain && (
      <div
        className="gap-component"
        style={{
          backgroundColor: "transparent",
        }}
      >
        <h2 className="txt-sub-title">popular job titles</h2>
        Check out the most popular job titles in the industry for your selected
        job family
        <div style={{ display: "flex", justifyContent: "center" }}>
          <img
            src={wordCloud[props?.menuJobSelectedMain?.id]}
            alt=""
            // style={{ height: 579 }}
            className={"img-job-title"}
          />
        </div>
      </div>
    )
  );
};

export default React.memo(JobTitle);
