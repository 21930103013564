export const BASE_URL =
  "https://skillsintelligence-backend-sifoe7oatq-uc.a.run.app";

const endPoint = {
  getSector: BASE_URL + "/jobintelligence/v1/Sectors",
  getAlternateTitles: BASE_URL + "/jobintelligence/v1/getAlternateTitles",
  getDetailSector: BASE_URL + "/jobintelligence/v1/Sectors/",
  submitForm: "https://info.disprz.com/l/975733/2022-07-25/fl7p",
  getJobFamily: BASE_URL + "/jobintelligence/v1/Track/jobFamilyWithTracks",
  getTracks: BASE_URL + "/jobintelligence/v1/Tracks",
  getPyramidGraph: BASE_URL + "/jobintelligence/v1/getPyramidGraph",
};

export { endPoint };
