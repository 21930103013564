const dataSample = [
  {
    category: "Functional",
    data: [],
    dataPopup: [],
  },
  {
    category: "Digital",
    data: [],
    dataPopup: [],
  },
  {
    category: "Leadership",
    data: [],
    dataPopup: [],
  },
];

const dataImgContent = {
  pro_1_udemy: process.env.PUBLIC_URL + "/udemy.png",
  pro_2_coursera: process.env.PUBLIC_URL + "/coursera.png",
  pro_3_edx: process.env.PUBLIC_URL + "/edx.png",
  pro_4_go1: process.env.PUBLIC_URL + "/go1.png",
  pro_5_learning: process.env.PUBLIC_URL + "/in.png",
  pro_6_pluralsight: process.env.PUBLIC_URL + "/plural.png",
  pro_7_youtube: process.env.PUBLIC_URL + "/youtube.svg",
  pro_8_articles: process.env.PUBLIC_URL + "/articles.svg",
  pro_9_podcasts: process.env.PUBLIC_URL + "/podcasts.svg",
};

const dataTxtContent = {
  pro_7_youtube: "Youtube",
  pro_8_articles: "Articles",
  pro_9_podcasts: "Podcasts",
};

const dataTitleContent = {
  pro_1_udemy: "Udemy",
  pro_2_coursera: "Coursera",
  pro_3_edx: "edX",
  pro_4_go1: "go1",
  pro_5_learning: "LinkedIn Learning",
  pro_6_pluralsight: "Pluralsight",
  pro_7_youtube: "Youtube videos",
  pro_8_articles: "Articles",
  pro_9_podcasts: "Podcasts",
};

const titleOpenContent = {
  pro_7_youtube: "Video",
  pro_8_articles: "Article",
  pro_9_podcasts: "Podcast",
};

const wordCloud = [
  process.env.PUBLIC_URL + "/wordCloud/3.png",
  process.env.PUBLIC_URL + "/wordCloud/3.png",
  process.env.PUBLIC_URL + "/wordCloud/3.png",
  process.env.PUBLIC_URL + "/wordCloud/3.png",
  process.env.PUBLIC_URL + "/wordCloud/4.png",
  process.env.PUBLIC_URL + "/wordCloud/5.png",
  process.env.PUBLIC_URL + "/wordCloud/6.png",
  process.env.PUBLIC_URL + "/wordCloud/7.png",
  process.env.PUBLIC_URL + "/wordCloud/8.png",
  process.env.PUBLIC_URL + "/wordCloud/9.png",
  process.env.PUBLIC_URL + "/wordCloud/10.png",
  process.env.PUBLIC_URL + "/wordCloud/11.png",
  process.env.PUBLIC_URL + "/wordCloud/12.png",
  process.env.PUBLIC_URL + "/wordCloud/13.png",
  process.env.PUBLIC_URL + "/wordCloud/14.png",
  process.env.PUBLIC_URL + "/wordCloud/15.png",
  process.env.PUBLIC_URL + "/wordCloud/16.png",
  process.env.PUBLIC_URL + "/wordCloud/17.png",
  process.env.PUBLIC_URL + "/wordCloud/18.png",
  process.env.PUBLIC_URL + "/wordCloud/19.png",
  process.env.PUBLIC_URL + "/wordCloud/20.png",
  process.env.PUBLIC_URL + "/wordCloud/21.png",
  process.env.PUBLIC_URL + "/wordCloud/22.png",
  process.env.PUBLIC_URL + "/wordCloud/23.png",
  process.env.PUBLIC_URL + "/wordCloud/24.png",
  process.env.PUBLIC_URL + "/wordCloud/25.png",
];

export {
  dataSample,
  dataImgContent,
  dataTxtContent,
  wordCloud,
  dataTitleContent,
  titleOpenContent,
};
