import React from "react";
import "../../../App.css";

function PrivacyComponent(props) {
  return (
    <div
      className="privacy-view"
      style={{ marginTop: props.menuJobSelectedMain ? 0 : 0 }}
    >
      <div className="row g-0 privacy">
        <div className="col-lg-6 col-md-12 d-flex justify-content-lg-start  justify-content-center">
          <a className="txt-footer" href="https://disprz.com/privacy-policy/">
            Privacy Policy
          </a>
          <div className="txt-footer"> | </div>
          <a className="txt-footer" href="https://disprz.com/terms-of-service/">
            Terms of Service
          </a>
          <div className="txt-footer"> | </div>
          <a className="txt-footer" href="https://disprz.com/cookie-policy/">
            Cookie Policy
          </a>
          <div className="txt-footer"> | </div>
          <a
            className="txt-footer"
            href="https://disprz.com/regulatory-requirements/"
          >
            Regulatory Requirements
          </a>
        </div>
        <div className="col-lg-6 col-md-12 d-flex justify-content-lg-end justify-content-center">
          <div className="txt-footer">
            Copyright Heuristix Digital Technologies Private Limited | disprz®
          </div>
        </div>
      </div>
    </div>
  );
}

export default React.memo(PrivacyComponent);
